var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "g-recaptcha",
    attrs: {
      "data-sitekey": _vm.siteKey || _vm.$recaptcha.siteKey,
      "data-size": _vm.computedDataSize,
      "data-theme": _vm.dataTheme,
      "data-badge": _vm.dataBadge,
      "data-tabindex": _vm.dataTabindex,
      "data-callback": "recaptchaSuccessCallback",
      "data-expired-callback": "recaptchaExpiredCallback",
      "data-error-callback": "recaptchaErrorCallback",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }